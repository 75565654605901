<template>
    <div class="d-flex flex-grow-1">
        <top-menu @toggleDrawer="handleToggleDrawer" :is-dashboard="true" />
        <admin-drawer-menu :mini="mini" />
        <v-main>
            <v-container fluid>
                <slot></slot>
            </v-container>
        </v-main>
    </div>
</template>

<script>
    import AdminDrawerMenu from "../components/admin/AdminDrawerMenu";
    import TopMenu from "../components/TopMenu";

    export default {
        name: "admin-dashboard-layout",
        components: {TopMenu, AdminDrawerMenu},
        data() {
            return {
                mini: true
            }
        },
        methods: {
            handleToggleDrawer() {
                this.mini = !this.mini
                localStorage.setItem('admin_menu_mini', this.mini ? '1' : '0')
            }
        },
        mounted() {
            this.mini = localStorage.getItem('admin_menu_mini') === '1' ? true : false
        }
    }
</script>

<style scoped>

</style>
